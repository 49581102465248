/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

module.exports = {
  'organization': {
    title: 'Organization',
    icon: 'domain'
  },
  'build': {
    title: 'Build',
    icon: 'hammer-screwdriver',
    disabled: true
  },
  'environment': {
    title: 'Environment',
    icon: 'earth'
  }
};
