/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */


let menu = [
  {
    title: 'My Account',
    children: [
      {
        icon: 'mdi-card-account-details',
        title: 'Profile',
        subtitle: 'Manage Account & Profile',
        route: 'Profile'
      },
    ]
  },
  {divider: true},
  {
    title: 'My Organization',
    children: [
      {
        icon: 'mdi-domain',
        title: 'Organizations',
        subtitle: 'Manage Organization Accounts',
        route: 'Accounts'
      },
      {
        icon: 'mdi-account-multiple',
        title: 'Users',
        subtitle: 'Manage Users Accounts',
        route: 'Users'
      },
      {
        icon: 'mdi-account-group',
        title: 'Groups',
        subtitle: 'Manage & Link Users to Groups',
        route: 'Groups',
      },
      {
        icon: 'mdi-lock',
        title: 'Permissions',
        subtitle: 'Manage Users Access Levels & Permissions',
        route: 'Roles'
      },
    ]
  },
  {divider: true},
  {
    icon: 'mdi-logout',
    title: 'Log out',
    click: 'logout'
  },
];

module.exports = menu;
