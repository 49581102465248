<template>

  <v-app-bar
      id="app-bar"
      app
      dark
      height="75"
      :color="(envSelected && envSelected.color) ? envSelected.color.code : '#4caf50'"
  >
    <v-btn
        class="mr-3"
        elevation="1"
        fab
        small
        @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-menu
        offset-y :close-on-content-click="true"
        width="200"
    >
      <template v-slot:activator="{ on, attrs }" v-show="activeSection.title">
        <v-btn
            dark
            v-bind="attrs"
            v-on="on"
            id="section-menu"
        >
          <v-icon class="mr-3">
            mdi-{{ activeSection.icon }}
          </v-icon>
          {{ activeSection.title }}
          <v-icon class="ml-3">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list
          dense
          width="250"
      >
        <v-subheader>
          <h3>
            <strong>Switch Section</strong>
          </h3>
        </v-subheader>
        <v-divider class="mb-2 mt-2"/>

        <v-list-item-group
            color="primary"
        >
          <v-list-item
              v-for="(section, key) in sections"
              :key="key"
              link
              @click="openSection(key)"
              class="lighten-5"
              :disabled="section.disabled"
              :class="{'text-muted': section.disabled, 'text-primary': section.title === activeSection.title}"
          >
            <v-list-item-icon
                class="mr-2"
            >
              <v-icon left
                      :class="{'text-muted': section.disabled, 'text-primary': section.title === activeSection.title}"
              >
                mdi-{{ section.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content
                :class="{'text-muted': section.disabled, 'text-primary': section.title === activeSection.title}"
            >
              <v-list-item-title class="font-weight-medium">
                {{ section.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <v-spacer/>

    <v-menu
        offset-y :close-on-content-click="true"
        width="200"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            id="switch-env-menu"
            v-show="environments && environments.length > 0"
            dark
            v-bind="attrs"
            v-on="on"
        >
          <v-icon class="mr-3">
            mdi-earth
          </v-icon>
          {{ envSelected.name }}
          <v-icon class="ml-3">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list
          dense
          width="200"
          v-show="environments && environments.length > 0"
      >
        <v-subheader>
          <h3>
            <strong>Switch Environment</strong>
          </h3>
        </v-subheader>
        <v-divider class="mb-2 mt-2"/>

        <v-hover
            v-for="(item, index) in environments"
            :key="index"
            v-slot:default="{ hover }"
            open-delay="200"
        >
          <v-list-item id="env_menu_list"
                       :style="(hover) ? {'background-color': item.color.code } : ''"
          >
            <v-list-item-title
                :style="{'color': item.color.code }"
                :class="(hover) ? `white--text font-weight-medium`: ''"
                @click="switchEnvironment(item)"
            >
              <v-icon left :color="(hover) ? 'white': item.color.code">
                mdi-earth
              </v-icon>
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-action
                v-if="canAccess({method: 'delete', route: '/environments/:env'}) && item.code.toUpperCase() !== 'PORTAL'">
              <v-icon right color="error" @click="deleteEnvironment(item)">mdi-delete</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-hover>
      </v-list>
    </v-menu>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            id="new-env-btn"
            v-show="canAccess({method: 'put', route: '/environments'})"
            class="ml-2"
            fab
            small
            dark
            v-bind="attrs"
            v-on="on"
            @click="createNewEnvironment"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Create New Environment</span>
    </v-tooltip>

    <div class="mx-10"/>

    <v-menu
        bottom
        left
        min-width="400"
        offset-y
        origin="top right"
        :close-on-click="false"
        :close-on-content-click="true"
        transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
            id="global-menu"
            class="ml-2 mr-2"
            min-width="0"
            text
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-account</v-icon>
          {{ username }}
        </v-btn>
      </template>

      <v-list
          v-if="topMenu && topMenu.length > 0"
          max-width="400"
          :tile="false"
          subheader
          flat
      >
        <template v-for="(item, i) in topMenu">
          <v-divider v-if="item.divider" :key="`divider-${i}`" class="mb-2 mt-2"/>

          <div :key="`item-${i}`" v-if="!item.divider && !item.to && item.children">
            <v-subheader>{{ item.title }}</v-subheader>
            <app-bar-item
                v-for="(childItem, j) in item.children"
                :key="j"
                link
                :to="getPage(childItem)"
            >
              <template>
                <v-list-item-icon>
                  <v-icon
                      v-text="childItem.icon"
                  ></v-icon>
                </v-list-item-icon>
                <v-list-item-content @click="handleMenuItem(childItem, $event)">
                  <v-list-item-title v-text="childItem.title"></v-list-item-title>
                  <v-list-item-subtitle v-text="childItem.subtitle"></v-list-item-subtitle>
                </v-list-item-content>
              </template>

            </app-bar-item>
          </div>
          <app-bar-item :key="`item-${i}`" v-if="!item.divider && !item.children" @click="handleMenuItem(item)">
            <v-list-item-icon>
              <v-icon
                  v-text="item.icon"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="handleMenuItem(item, $event)">
              <v-list-item-title v-text="item.title"></v-list-item-title>
              <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
            </v-list-item-content>

          </app-bar-item>
        </template>
      </v-list>
    </v-menu>

  </v-app-bar>

</template>

<script>
// Components
import {VHover, VListItem} from 'vuetify/lib'

import menu from '../../../../config/topMenu';
import sections from '../../../../config/sections';

// Utilities
import {mapMutations, mapState} from 'vuex'
import globalMixins from "../../../../mixins/globalMixins";

export default {
  name: 'DashboardCoreAppBar',

  mixins: [globalMixins],

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({hover}) => {
              return h(VListItem, {
                attrs: this.$attrs,
                class: {
                  'black--text': !hover,
                  'white--text lighten-4 elevation-12': hover,
                },
                style: {
                  'background-color': (hover) ? '#26C6DA' : ''
                },
                props: {
                  activeClass: '',
                  dark: hover,
                  link: true,
                  ...this.$attrs,
                },
              }, this.$slots.default)
            },
          },
        })
      },
    },
  },

  props: {
    environments: {
      type: Array
    },
    envSelected: {
      type: Object
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    ModuleName: 'Dashboard',
    topMenu: [],
    sections: sections,
    activeSection: {
      title: null,
      icon: null
    }
  }),

  watch: {

    '$route': function (newRoute, oldRoute) {
      if (newRoute.name !== oldRoute.name) {
        let name = newRoute.name;
        if (newRoute.meta && newRoute.meta.breadcrumb) {
          let leaf = newRoute.meta.breadcrumb[newRoute.meta.breadcrumb.length - 1];
          if (leaf && leaf.title && leaf.active) {
            name = leaf.title;
          }
        }
        this.ModuleName = name;
      }

      if (newRoute.meta && newRoute.meta.section.name) {
        this.activeSection = this.sections[newRoute.meta.section.name];
      }
    },
    envSelected: async function (val) {
      await this.renderMenu();
    },
  },

  computed: {
    ...mapState(['drawer']),

    username: function () {
      const user = this.$store.getters['auth/getUser'];
      return (user) ? user.firstName : null;
    }
  },

  beforeDestroy() {
    this.topMenu = [];
  },

  async created() {
    await this.renderMenu();

    if (this.$router.currentRoute.meta && this.$router.currentRoute.meta.section.name) {
      this.activeSection = this.sections[this.$router.currentRoute.meta.section.name];
    }
  },

  methods: {

    async renderMenu() {
      let topMenu = await this.updateMenuEntries(JSON.parse(JSON.stringify(menu)));
      this.topMenu = topMenu;
    },

    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),

    logout: function () {
      if (confirm('Are you sure you want to Log out ?')) {

        if (localStorage.getItem('project')) {
          // if project is set, redirect back to portal
          let saasLink = `${process.env.VUE_APP_M360_SAAS_UI}/projects/saas`;

          localStorage.removeItem('project');
          this.$store.dispatch('auth/clear');
          this.$store.dispatch('env/clear');
          window.location.href = saasLink;
        } else {
          const token = this.$store.getters['auth/getToken'];

          let objRes = {
            'url': '/logout',
            'method': 'get',
            'headers': {
              'env': 'portal'
            }
          };
          if (token && token.refresh_token) {
            objRes['params'] = {
              'refresh': token.refresh_token
            };
          }
          this.getSendData(objRes).then(() => {
            this.$store.dispatch('auth/clear');
            this.$store.dispatch('env/clear');
            this.$cookies.remove('token');
            this.clearMessages();

            if (this.$router.currentRoute.name !== 'Login') {
              this.$router.push({name: "Login"});
            }

            setTimeout(() => {
              this.pushMessage({
                type: 'success',
                title: 'Logged Out',
                text: 'You have logged out of M360 Console, see you back soon.'
              });
            }, 1000);
          }).catch(error => {
            console.error(error);
            this.$store.dispatch('auth/clear');
            this.$store.dispatch('env/clear');
            this.$cookies.remove('token');
            this.clearMessages();

            if (this.$router.currentRoute.name !== 'Login') {
              this.$router.push({name: "Login"});
            }
          });
        }

      }
    },

    switchEnvironment: async function (item) {
      const env = await this.getSendData({
        url: `/consoleapi/environments/${item.value}`,
        method: "get",
      });

      this.$emit('switchEnv', item);
      this.$store.dispatch('auth/setACL', {env: env.item.registry.code.toLowerCase(), acl: env.item.acl});

      if (item.value !== this.envSelected.value) {
        let myRoute = this.$router.currentRoute;
        let oldPath = JSON.parse(JSON.stringify(myRoute.params));
        if (!myRoute.params) {
          myRoute.params = {};
        }
        myRoute.params.envCode = item.value;

        if (oldPath.envCode !== myRoute.params.envCode) {
          this.$nextTick(() => {
            this.$router.push({name: myRoute.name, params: myRoute.params || {}});
            this.$nextTick(() => {
              setTimeout(function () {
                window.location.reload();
              }, 1800)
            });
          })
        }
      }
    },

    deleteEnvironment: function (item) {
      const _self = this;

      if (item.code.toUpperCase() === 'PORTAL') {
        _self.pushMessage({
          type: 'error',
          title: `Invalid Operation!`,
          text: `You cannot delete the environment that this console is running in!`
        });
        _self.scrollToTop();
      } else if (confirm(`Are you sure you want to delete environment '${item.code}' ?`)) {
        this.getSendData({
          url: `/consoleapi/environments/${item.value}`,
          method: "delete",
        }).then(() => {
          _self.pushMessage({
            type: 'success',
            title: `Environment ${item.code} Deleted`,
            text: `Environment ${item.code} and its settings, attached resources, ACL permissions, and applications have been deleted!`
          });
          _self.$store.dispatch('auth/removeENVACL', item.code);
          _self.scrollToTop();
          _self.$store.dispatch('env/clear',);
          _self.$emit('refreshEnv');
          setTimeout(() => {
            window.location.href = window.location.href.replace(item.code, 'portal');
          }, 2000);
        });
      }
    },

    createNewEnvironment: function () {
      if (this.environments && this.environments.length > 0) {
        if (confirm("You are about to create a new Environment from this current Environment. Are you sure you want to proceed ?")) {
          this.goToPage({route: 'createEnvironment'});
        }
      } else {
        this.goToPage({route: 'createEnvironment'});
      }

    },

    openSection: function (section) {
      let myRoute;

      this.$router.options.routes.forEach((oneRoute) => {
        if (oneRoute.meta && oneRoute.meta.section && oneRoute.meta.section.name === section && oneRoute.meta.section.default) {
          myRoute = oneRoute;
        } else if (!myRoute && oneRoute.children && oneRoute.children.length > 0) {
          oneRoute.children.forEach((oneChildRoute) => {
            if (oneChildRoute.meta && oneChildRoute.meta.section && oneChildRoute.meta.section.name === section && oneChildRoute.meta.section.default) {
              myRoute = oneChildRoute;
            }
          });
        }
      });

      if (myRoute) {
        this.$router.push({name: myRoute.name});
      }
    }
  },
}
</script>

<style>
.sections {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: solid 1px white;
  border-bottom: none;
  margin: 14px 5px 0 5px;
  height: 60px;
}

.sections h3 {
  cursor: pointer;
  margin: 0;
  display: block;
  width: 100%;
  height: 59px;
  padding: 15px;
}

.sections h3:hover {
  background-color: #EEEEEE;
  color: black;
}

.sections h3:hover i {
  color: black;
}

.sections .active {
  background-color: white !important;
  color: black !important;
}

.sections .active i {
  color: black !important;
}

#env_menu_list .v-list-item__title {
  cursor: pointer;
}
</style>
